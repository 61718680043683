
import {request} from "../index";
function getBeans(){
    return request({
        url: `/beans`,
        method:'get'
    })
}
function getCardRecharge(data){
    return request({
        url: `/card-recharge`,
        method:'post',
        data
    })
}
function getImageBase64(data){
    return request({
        url: `/image/base64`,
        method:'post',
        data
    })
}

function getSet_steam_url(data){
    return request({
        url: `/set_steam_url`,
        method:'post',
        data
    })
}

function getSendSms(data) {
    data.type = 'sms_sent_success'
    return request({
        url: `/send_sms`,
        method:'post',
        data
    })
}
function getpassword(data){
    return request({
        url: `/reset_password`,
        method:'post',
        data
    })
}
function getSet_inviter(data){
    return request({
        url: `/set_inviter`,
        method:'post',
        data
    })
}
function getSet_avatar(data){
    return request({
        url: `/set_avatar`,
        method:'post',
        data
    })
}
function getSet_name(data){
    return request({
        url: `/set_name`,
        method:'post',
        data
    })
}

function getStorage(page,status = 0,sort = 0){
    return request({
        url: `/storage?page=${page}&status=${status}&sort=${sort}`,
        method:'get',

    })
}

function getCash(data){
    return request({
        url: `/cash`,
        method:'post',
        data

    })
}
function getExtract(data){
    return request({
        url: `/extract`,
        method:'post',
        data

    })
}
function getPromote(){
    return request({
        url: `/promote`,
        method:'get',


    })
}

function getRecharge_record(page){
    return request({
        url: `/recharge_record?page=${page}`,
        method:'get',


    })
}
async function getRecharge(data){
    return request({
        url: `/recharge`,
        method:'post',
        data
    })

}
function get_set_invite_code(data){
    return request({
        url: `/set_invite_code`,
        method:'post',
        data
    })
}


function getUsetInof() {
    return request({
        url: `/me`,
        method:'get'
    })
}
function getUsetChange_records(page,type) {
    if(!type){
        return request({
            url: `/bean/change_records?page=${page}`,
            method:'get'
        })
    }else {
        return request({
            url: `/bean/change_records?page=${page}&type=${type}`,
            method:'get'
        })
    }

}
function getbeans_first() {
    return request({
        url: `/beans/first`,
        method:'get'
    })
}
function getBeanChange_type() {
    return request({
        url: `/bean/change_type`,
        method:'get'
    })
}

export default {
    getBeans,
    getCardRecharge,
    getImageBase64,
    getSet_steam_url,
    getSendSms,
    getpassword,
    getSet_inviter,
    getSet_avatar,
    getSet_name,
    getStorage,
    getCash,
    getExtract,
    getPromote,
    getRecharge_record,
    getRecharge,
    getUsetInof,
    getUsetChange_records,
    getbeans_first,
    get_set_invite_code,
    getBeanChange_type
}
