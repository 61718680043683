<template>
  <div class="d-flex">

    <!-- 内容区域 -->
    <div class="content mt-4">
      <div class="d-flex align-items-center">
        <div class="d-flex flex-wrap">
          <div @click="bill('1')" :class="status=='1'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            提取完成
          </div>
          <div @click="bill('2')" :class="status=='2'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            已兑换
          </div>
          <div @click="bill('3')" :class="status=='3'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            冻结中
          </div>
          <div @click="bill('4')" :class="status=='4'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            申请提货
          </div>
          <div @click="bill('5')" :class="status=='5'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            正在发货
          </div>
          <div @click="bill('6')" :class="status=='6'?'active' : ''" class="bg-brown px-4 mr-2 py-1 mb-3 cursor-pointer">
            等待收货
          </div>
        </div>
      </div>
      <div class="flex-wrap mt-3 row d-flex" >
        <div class="back-gun mb-3" style="" v-for="(item,index) in StorageList" :key="index">
          <weapon class="position-relative " :res-data="item" :class="item == 2? 'border-yellow' :''">
            <div v-if="item == 2" class="position-absolute" style="right: 10px;top: 10px">
             
              <div> 
                <i class="iconfont icon-xuanzhong" style="1rem"></i>
              </div>
            </div>
          </weapon>
        </div>
          <vacancy  v-show="StorageList.length == 0"> </vacancy>
      </div>
      <el-pagination
              background
              layout="prev, pager, next"
              :total="total"
              :page-size="pageSize"
              @current-change="currentChange">
      </el-pagination>

      <div v-show="StorageList.length < 20 && StorageList.length != 0" style="height: 500px">

      </div>
    </div>

  </div>
</template>

<script>
  import $api from '@/api/user/index'
  import weapon from "./common/weapon";
  export default {
    name: "index",
    components:{
      weapon,

    },
    data(){
      return {
        // 弹出框
        ReturnReason:false,
        StorageList:[],
        // 翻页数量
        total:0,
        pageSize:0,

        status:1
      }
    },
    created() {
    },
    mounted() {
      this.init()
    },
    methods:{
      init(){
        this.getStorage()
      },
      shoppinng(item,index){
        // 需要判断是不是退回的状态，之后在弹出窗口
        this.ReturnReason = true
        console.log(item,index)
      },
      // 获取仓库的信息
      async getStorage(page = 1,status = 1){
        try {
          let {data,code} = await $api.getStorage(page,status)
          if(code == 500)return
          data.data.forEach(item =>{
            if(item.back_message != ''){
              console.log(123)
            }
            item.isShow = false
          })
          this.StorageList = data.data
          this.total = data.total
          this.pageSize = data.per_page
        }catch (e) {
          console.log(e)
        }
      },
      // 显示仓库状态
      bill(val){
        this.status = val
        let loading = this.$loading({lock:true,text: '正在加载',  spinner: 'el-icon-loading',background: 'rgba(0, 0, 0, 0.7)'})
        setTimeout(() => {
          this.getStorage(1,val)
          loading.close();
        }, 500);

      },
      currentChange(val){
        this.getStorage(val,this.status)
        window.scrollTo(0,0);
      },
    }
  }
</script>

<style scoped lang="scss">
  .back-gun{
    width: 9.3%;
    @media (max-width: 1600px) {
      width: 15%;
    }
    @media (max-width: 1366px) {
      width: 20%;
    }
    @media (max-width: 1200px) {
      width: 25%;
    }
    @media (max-width: 960px) {
      width: 33%;
    }
  }
  .side-box {
    width: 204px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,.5)
  }
  .content {
    width: 90%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 96%;
    }
    & .active{
      background: #000000;
    }
  }
</style>
