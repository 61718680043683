<template>
    <!-- 枪支的item，基本公共样式 - 看接口返回的信息需要 -->
    <div class="weapon bg-size-100 bg-position-center bg-repeat " style="width:96%;margin:0 auto;"
        :style="`background-image: url(${resData.lv_bg_image})`">

        <div class="bg-repeat position-relative
        justify-content-center align-items-center border-bottom-0">
            <div class="text-center d-flex
             justify-content-center w-100 p-2">
                <div class="" style="font-size:12p;height:40px;">
                    <!-- <money /> -->
                    <!-- CS:GO 10周年印花胶囊 -->
                    {{ resData.name }} <br>
                    {{ resData.dura_alias }}
                </div>
                <div style=""></div>
            </div>
            <div class="d-flex justify-content-center" style="">
                <img style="width: 75px;height: 75px;" :src="resData.cover" alt="">
            </div>

        </div>
        <div class="px-2 text-center  border-top-0 " style="line-height: 24px;font-size: 12px;">
            <!-- <div class="text-overflow-1">{{ resData.name }}</div>
            <div class="text-overflow-1">{{ resData.dura_alias || '暂无' }}</div> -->
            <money /> {{ resData.bean }}
        </div>
        <slot>

        </slot>
    </div>
</template>

<script>
export default {
    name: "weapon",
    props: {
        // 传输武器整体宽度
        width: {
            type: String,
            default: "180px"
        },
        // 武器显示的高度，宽度和整体宽度是一样的
        heightWeapon: {
            type: String,
            default: "150px"
        },
        bgSize: {
            type: Object,
            default() {
                return {
                    height: '102px'
                }
            }
        },
        weaponSize: {
            type: Object,
            default() {
                return {
                    width: '115px',
                    marginTop: '25px'
                }
            }
        },
        //    传输的item
        resData: {
            type: Object,
            default() { return {} }
        }
    },
}
</script>

<style scoped lang="scss">
.weapon {
    @media (max-width: 1200px) {
        font-size: 12px !important;
    }

    &-img {
        height: 120px;

        @media (max-width: 1200px) {
            height: 100px;
            font-size: 12px !important;
        }
    }

    &-name {
        padding: 10px 0;
    }
}
</style>
